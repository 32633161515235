// Fonts
// ---------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Yrsa:wght@300..700&display=swap');
$font-family-heading:   "Lexend", sans-serif;
$font-family-secondary: "Yrsa", serif;

// Colors
// ---------------------------------------------------------
$color-primary:         #43b9b7; // rgba(67, 185, 183, 1) // hsl(178.98, 46.83%, 49.41%)
$color-black:           #282828; // rgba(40, 40, 40, 1) // hsl(0, 0%, 15.69%)
$color-black-shade:     #101010; // rgba(16, 16, 16, 1) // hsl(0, 0%, 6.27%)
$color-black-tint:      #535353; // rgba(83, 83, 83, 1) // hsl(0, 0%, 32.55%)
$color-brand-yellow:    #FEC729; // rgba(254, 199, 41, 1) // hsl(44.51, 99.07%, 57.84%)
$magenta-vivid:         #F72585; // rgba(247, 37, 133, 1) // hsl(332.57, 92.92%, 55.69%)
$remy:                  #FEE7F1; // rgba(254, 231, 241, 1) // hsl(333.91, 92%, 95.1%)
$vivid-violet:          #B5179E; // rgba(181, 23, 158, 1) // hsl(308.73, 77.45%, 40%)
$classic-rose:          #FDD3F7; // rgba(253, 211, 247, 1) // hsl(308.57, 91.3%, 90.98%)
$seance-purple:         #7209B7; // rgba(114, 9, 183, 1) // hsl(276.21, 90.63%, 37.65%)
$blue-chalk:            #EED9FD; // rgba(238, 217, 253, 1) // hsl(275, 90%, 92.16%)
$purple-heart:          #560BAD; // rgba(86, 11, 173, 1) // hsl(267.78, 88.04%, 36.08%)
$selago:                #E9D9FC; // rgba(233, 217, 252, 1) // hsl(267.43, 85.37%, 91.96%)
$blue-violet:           #480CA8; // rgba(72, 12, 168, 1) // hsl(263.08, 86.67%, 35.29%)
$moon-raker:            #EAE1F9;
$dark-blue:             #3A0CA3; // rgba(58, 12, 163, 1) // hsl(258.28, 86.29%, 34.31%)
$blue-ribbon:           #3F37C9; // rgba(63, 55, 201, 1) // hsl(243.29, 57.48%, 50.2%)
$link-water:            #E4E3F7; // rgba(228, 227, 247, 1) // hsl(243, 55.56%, 92.94%)
$royal-blue:            #4361EE; // rgba(67, 97, 238, 1) // hsl(229.47, 83.41%, 59.8%)
$hawkes-blue:           #DFE4FC; // rgba(223, 228, 252, 1) // hsl(229.66, 82.86%, 93.14%)
$cornflower-blue:       #4895EF; // rgba(72, 149, 239, 1) // hsl(212.34, 83.92%, 60.98%)
$picton-blue:           #4CC9F0; // rgba(76, 201, 240, 1) // hsl(194.27, 84.54%, 61.96%)
$mystic:                #ECF0F4; // rgba(236, 240, 244, 1) // hsl(210, 26.67%, 94.12%)
$pale-sky:              #6B7280; // rgba(107, 114, 128, 1) // hsl(220, 8.94%, 46.08%)
$shark:                 #292C30; // rgba(41, 44, 48, 1) // hsl(214.29, 7.87%, 17.45%)
$gallery:               #EBEBEB; // rgba(235, 235, 235, 1) // hsl(0, 0%, 92.16%)
$black-squeeze:         #edf6f9; // rgba(237, 246, 249, 1) // hsl(195, 50%, 95.29%)
$mirage:                #1b2331; // rgba(15, 22, 35, 1) // hsl(219, 40%, 9.8%)
$mirage2:               #242e41; // rgba(15, 22, 35, 1) // hsl(219, 40%, 9.8%)
$ebony:                 #0F1623; // rgba(15, 22, 35, 1) // hsl(219, 40%, 9.8%)
$ebony2:                #080e18; // rgba(9, 13, 21, 1) // hsl(220, 40%, 5.88%))
$jon:                   #3c1f2b;
$wine-berry:            #3A1635;
// WeWill branding
$ribbon-blue:           #003e99; // WeWill brand primary
$smalt:                 #003e99; // WeWill brand primary dark    

// Units
// ---------------------------------------------------------
:root {
    --border-radius:    1rem;
    --font-size-base:   18px;
    --spacing:          2rem; /* cards space between */
    --offset:           0.5em; /* cards offset overlap */
    $bs-dropdown-link-hover-bg: none !important;
    --bs-accordion-bg:  none;
}

// Gradients & Borders
// ---------------------------------------------------------
$gradient-bg: linear-gradient(
    170deg, 
    $mystic,
    $gallery,
    $pale-sky
);
$gradient-card: linear-gradient(
    100deg,rgba(247, 37, 133, 0.5), rgba(225, 0, 146, 0.5), rgba(198, 0, 159, 0.5), rgba(162, 0, 172, 0.5),rgba(114, 9, 183, 0.5) 
); 
$gradient-hero: linear-gradient(
    100deg,#f72585, #e10092, #c6009f, #a200ac, #7209b7
);
$gradient-hero2: linear-gradient(
    100deg,#f72585 0%, #c6009f 100%
);
$gradient-hero3: linear-gradient(
    100deg,#92fe9d 0%, #00c9ff 100%
);
$gradient-hero3: linear-gradient(
    100deg,#92fe9d 0%, #00c9ff 100%
);
$gradient-hero4: linear-gradient(
    100deg,#5fffda 0%, #ffef8a 100%
); 
$gradient-hero-dark: linear-gradient( 
    -110deg, 
    #2563eb,
    #ff5acd,
    #fbda61
); 

body {
    font-family: $font-family-heading;
    font-size: --font-size-base;

    a {
        text-decoration-line: underline;
        text-underline-offset: 10%;
    }

    p { 
        line-height: 180%;
        font-weight: 400;
    }

    h2 {
        font-weight: 600;
        letter-spacing: -0.03rem;
    }

    h3 {
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01rem;
    }

    .btn {
        position: relative;
        padding: 0.75rem 2.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: white;
        background: $magenta-vivid;
        border-radius: 2rem;

        // i {
        //     position: absolute;
        //     display: inline-block;
        //     font-size: 2rem;
        // }

        &:hover {
            color: white;
            background-color: hsl(332.57, 92.92%, 55.69%, 0.2);
            border: 1px solid $magenta-vivid;
        }
    }

    .btn-secondary {
        position: relative;
        padding: 0.75rem 2.5rem;
        font-size: 1.2rem;
        color: white;
        border: 2px solid $magenta-vivid;
        background: none;
        border-radius: 2rem;

        // i {
        //     position: absolute;
        //     display: inline-block;
        //     font-size: 2rem;
        // }

        &:hover {
            color: white;
            background-color: hsl(332.57, 92.92%, 55.69%, 0.2);
            border: 2px solid $magenta-vivid;
        }
    }
}

.wrapper {  
    // height: 120%;
    z-index: 10;
    padding-bottom: 4rem;
    margin-bottom: 36rem;
    position: relative;
    background: white;

    .border-rule {
        border-top: 1px solid $gallery;
    }  

    &-dark {
        background: $ebony2;
        color: rgba(236, 240, 244,0.8);

        h2, h3, h4 {
            color: white;
        }

        .border-rule {
            border-top: 1px solid $mirage;
        }  
    }

    // LS Logo
    img.legal-creditation {
        transition: all 0.3s ease-in-out;
        border-radius: 100%;

        &:hover {
            -webkit-box-shadow:0px 0px 16px 12px rgba(255, 240, 202, 0.2);
            -moz-box-shadow: 0px 0px 16px 12px rgba(255, 240, 202, 0.2);
            box-shadow: 0px 0px 16px 12px rgba(255, 240, 202, 0.2);
        }
    }
}

header {
    
    .navbar {
        background-image: none;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(67, 185, 183, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

      }

    .navbar-nav {
        margin-top: 2rem;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        border-bottom: 1px solid $mirage2;

        .nav-item {
            padding: 1rem 1.4rem;
            border-top: 1px solid $mirage2;

            &.active .nav-link {
                // border-bottom: 3px solid $color-black-tint;
                color: $color-primary;
            }
            .nav-link {
                padding: 0;

                &:after {
                    display: none;
                }

                i {
                    // chevron down - iconoir-nav-arrow-down
                }

                .dropdown-toggle {
                }
            }
        }

        .dropdown {
            
            &.show {
                transition: all 0.25s ease-out;
            }
            
            ul.dropdown-menu {
                border-radius: $border-radius;
                box-shadow: none;
                margin-top: 1rem;
                width: auto;
                background-color: $ebony;
                border: none;
                box-shadow: none;

                li a.dropdown-item {
                    padding: 1rem 0;
                    color: white;
                    font-size: 1.1rem;

                    &.active {
                        color: $color-primary;
                        background-color: transparent;
                        background-image: none;
                    }

                    &:hover {
                        color: $color-primary;
                        background-color: transparent;
                        background-image: none;
                    }
                }
            }    
        }

        .dropdown ul.dropdown-menu:before {
            visibility: hidden;
            content: "";
            border-bottom: 10px solid $ebony;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            position: absolute;
            top: -10px;
            right: 16px;
            z-index: 10;
        }

        .dropdown ul.dropdown-menu:after {
            visibility: hidden;
            content: "";
            border-bottom: 12px solid $pale-sky;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            position: absolute;
            top: -12px;
            right: 14px;
            z-index: 9;
        }
    }

    &.light {
        .navbar-nav {    
            .nav-item {
                &.active .nav-link {
                    // border-bottom-color: $black-squeeze;
                    color: $color-primary;
                }
                .nav-link {
                    color: $mystic;

                    // &.dropdown-toggle:after {
                    //     color: red;
                    // }
                    &.dropdown-toggle {

                        i {
                            color: white;
                            transition: all 0.125s ease-out;
                        }
                        &.show i {
                            color: $pale-sky;
                        }
                    }
                }
            }
        }
    }
}

.container-hero {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 1600 1188' viewBox='0 0 1600 1188'%3E%3Cpath d='M1600 1147.9 0 1188V0h1600z' style='fill:%230f1623'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    .hero {
        padding: 5rem 0 10rem 0;

        h1 {
            font-optical-sizing: auto;
            font-weight: 500;
            font-style: normal;
            font-size: 3rem;
            line-height: 110%;
            letter-spacing: -0.04rem;
            color: white;

            span {
                background: $gradient-hero2;
                background-position: 0 0rem;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            font-size: 1.2rem;
            font-weight: 400;
            color: $mystic;
        }

        .btn.btn-primary {
            padding: 0.75rem 1.5rem;
            border: none;
            border-radius: 0;
            font-optical-sizing: auto;
            font-weight: 500;
            font-style: normal;
            font-size: 1.2rem;
            outline: 2px solid $color-primary;
            color: $color-primary;
            background: none;
            transition: all 0.25s ease-out;
            border-radius: 10rem;

            &:hover {
                background: $color-primary;
                color: white;
            }
        }
        
        .btn.btn-secondary {
            color: $magenta-vivid;

            &:hover {
                color: white;
            }
        }
    }
}

.container-hero-home {
    margin-bottom: 0rem;
}

.container-hero-services {
    margin-bottom: 5rem;
    
    .hero-services {
        padding: 5rem 0;

        h1 {
            background: $gradient-hero3;
            background-position: 0 0rem;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            span {
                background: none !important;
                -webkit-background-clip:content-box;
                -webkit-text-fill-color: white;
            }
        }

        p {}

    }
}

.container-hero-process {
    margin-bottom: 5rem;
    
    .hero-process {
        padding: 5rem 0;

        h1 {
            background: $gradient-hero4;
            background-position: 0;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            span {
                background: none !important;
                -webkit-background-clip:content-box;
                -webkit-text-fill-color: $mystic;
            }
        }

        p {}

    }
}

.container-hero-sector {
    // margin-bottom: 6rem;
    
    .hero-process {
        padding: 5rem 0 0 0;

        h1 {
            background: $gradient-hero4;
            background-position: 0;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            span {
                background: none !important;
                -webkit-background-clip:content-box;
                -webkit-text-fill-color: $mystic;
            }
        }

        p {}

    }
}

.container-features {
    .features {
        .card-container { 
            &:hover {}
        }

        .card {
            z-index: 2;
            height: 34rem;
            padding: 1rem;
            border: none;
            border-radius: 0;
            color: white;
            border-radius: var(--border-radius);

            .card-body {

                .badge {
                    padding: 0.2rem 0.75rem;
                    margin-bottom: 1rem;
                    color: white;
                    border-radius: 10rem;
                }

                p {
                    // max-width: auto;
                    font-optical-sizing: auto;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 1.8rem;
                    letter-spacing: -0.02rem;
                    line-height: 140%;
                }

                a {
                    max-width: 70%;
                    color: white;
                    font-size: 1.5rem;
                    font-family: $font-family-secondary;
                    opacity: .8;

                    &:hover {
                        opacity: 1;
                        text-decoration: none;
                    }
                }
            }

            &.card-strategy {
                .badge { background-color: rgba(247, 37, 133, 0.5); }
                background-color: $magenta-vivid;
                background: linear-gradient(rgb(247,37,133,0.7), rgb(247,37,133,0.9)), url("../img/card-strategy.jpg");
                background-size: cover;
                background-position: center;
                background-blend-mode:multiply;
                color: white;
            }
            &.card-collaborate {
                .badge { background-color: rgba(181, 23, 158, 0.5); }
                background-color: $vivid-violet;
                background: linear-gradient(rgb(181,23,158,0.5), rgb(181,23,158,0.75)), url("../img/card-collaborate.jpg");
                background-size: cover;
                background-position: right top;
                background-blend-mode: multiply;
                color: white;
            }
            &.card-campaign {
                .badge { background-color: rgba(114, 9, 183, 0.5); }
                background-color: $seance-purple;
                background: linear-gradient(rgb(114,9,183,0.3), rgb(114,9,183,0.5)), url("../img/card-campaign.jpg");
                background-size: cover;
                background-position: right top;
                background-blend-mode:normal;
                color: white;
            }
            &.card-growth {
                .badge { background-color: rgba(72, 12, 168, 0.5); }
                background-color: $blue-violet;
                background: linear-gradient(rgb(72,12,168,0.4), rgb(72,12,168,0.6)), url("../img/card-growth.jpg");
                background-size: cover;
                background-position: right;
                background-blend-mode:normal;
                color: white;
            }
        }    
    }
}

.container-service {
    padding: 4rem 0 0 0;

    // Service category heading links
    a.service-category {
        text-decoration: none;

        h2 {
            position: relative;
            padding: 2rem;
            background-color: none;
            border-radius: $border-radius;
            color: $color-black;
            transition: all 0.5s ease-in-out;

            i {
                position: absolute;
                right: 2rem;
                bottom: -2rem;
                font-size: 1.7rem;
                color: white;
                opacity: 0;
                transition: all 0.75s ease-out;
            }
        }

        &:hover {
            h2 {
                padding-left: 2.5rem;
                color: white;
                transition: all 0.5s ease-in-out;

                i {
                    bottom: 2.2rem;
                    opacity: 1;
                }
            }            
        }

        &-digital:hover {
            h2 {
                background-image: linear-gradient(to right, #f72585, #e10092, #c6009f, #a200ac, #7209b7); 
            }            
        }

        &-marketing:hover {
            h2 {
                background-image: linear-gradient(to right, #b5179e, #9e07a0, #8301a1, #6404a2, #3a0ca3);
            }            
        }

        &-technology:hover {
            h2 {
                background-image: linear-gradient(to right, #3a0ca3, #3d27b6, #3f3cc9, #414fdc, #4361ee);
            }            
        }
        
    }

    &-digital a.service-card i {
        color: $magenta-vivid;
    }

    &-marketing a.service-card i {
        color: $vivid-violet;
    }

    &-technology a.service-card i {
        color: $royal-blue;
    }

    // Home - Service card items
    a.service-card {
        width: 40%;
        text-decoration: none;
        color: $color-black;

        &:nth-child(odd) {
            // margin-right: 2.5rem !important;
            // outline: 1px solid red;
        }
        &:nth-child(even) {
            // margin-left: 2.5rem !important;
            // outline: 1px solid green;
        }

        i {
            font-size: 2.5rem;
            opacity: 0.7;
            transition: all 0.25s ease-out;
        }

        .service-card-body {
            position: relative;

            i {
                position: absolute;
                right: 15%;
                color: $magenta-vivid;
                font-size: 1.7rem;
                opacity: 0;
                transition: all 0.5s ease-out;
            }

            h3 {
                padding-right: 15%;
                font-size: 1.5rem;
                // text-decoration-line: underline;
                // text-decoration-color: $color-black-tint;
                // text-underline-offset: 10%;
                text-decoration: none;
                transition: all 0.25s ease-out;
            }
    
            p {
                color: $color-black-tint;
            }
        }

        &:hover {

            i {
                color: $vivid-violet;
                opacity: 1;
            }

            .service-card-body {
                
                i {
                    right: 0;
                    opacity: 1;
                    color: $magenta-vivid;
                }

                h3 {
                    color: $magenta-vivid;
                    // text-decoration: none;
                    text-decoration-line: underline;
                    text-decoration-color: $magenta-vivid;
                    text-underline-offset: 10%;
                }
            }
        }
    }   
}

.container-services-list {
    background: transparent;

    i {
        font-size: 2.5rem;
    }

    .services-list {
        &_digital i {
            color: $magenta-vivid;
        }

        &_marketing i {
            color: $vivid-violet;
        }

        &_technology i {
            color: $royal-blue;
        }
    }

    .tag {
        &_digital {
            color: $magenta-vivid;
        }

        &_marketing {
            color: $vivid-violet;
        }

        &_technology {
            color: $royal-blue;
        }
    }
}

.container-values-list {
    background: transparent;

    h3 {
        font-size: 1.4rem;
    }

    i {
        font-size: 2.5rem;
    }

    .values-list {
        border: none;

        .card-body {
            position: relative;
            margin-top: 0.5rem !important;
            margin-bottom: 0.25rem !important;

            .icon:before {
                display: inline-block;
                position: absolute;
                content: "";
                height: 4rem;
                width: 4rem;
                top: 0.75rem;
                left: 1.75rem;
                background: white;
                border-radius: 3rem;
            }
        }

        .list-group {

            .list-group-item {
                padding: 1rem 1rem;
                line-height: 1.5rem;

                i {
                    font-size: 1.3rem;
                }
            }
        }

        &_1 {
            background-color: rgba(67, 185, 183, 0.05);
            
            i, .tag {
                color: $color-primary;
            }    
        }
        
        &_2 {
            background-color: rgba(247, 37, 133, 0.05);

            i, .tag {
                color: $magenta-vivid;
            }
        }
        
        &_3 {
            background-color: rgba(181, 23, 158, 0.05);

            i, .tag {
                color: $vivid-violet;
            }
        }
        
        &_4 {
            background-color: rgba(86, 11, 173, 0.05);

            i, .tag {
                color: $purple-heart;
            }
        }
        
        &_5 {
            background-color: rgba(63, 55, 201, 0.05);

            i, .tag {
                color: $blue-ribbon;
            }
        }
        
        &_6 {
            background-color: rgba(76, 201, 240, 0.05);

            i, .tag {
                color: $picton-blue;
            }
        }        
    }
}

.container-process {
    padding-bottom: 4rem;
    margin-top: 4rem;
    background-color:hsla(0,0%,100%,1);
    background-image:
    radial-gradient(at 43% 50%, hsla(51,100%,77%,0.14) 0px, transparent 50%),
    radial-gradient(at 57% 50%, hsla(166,100%,68%,0.09) 0px, transparent 50%),
    radial-gradient(at 50% 39%, hsla(51,100%,77%,0.06) 0px, transparent 50%);
    // background-image:
    // radial-gradient(at 43% 50%, hsla(286, 100%, 77%, 0.14) 0px, transparent 50%),
    // radial-gradient(at 57% 50%, hsla(255, 100%, 68%, 0.11) 0px, transparent 50%),
    // radial-gradient(at 50% 39%, hsla(276, 100%, 77%, 0.07) 0px, transparent 50%);
    background-size: 125% 100%;
    background-position: top 10% left -50%;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .intro {
        padding-bottom: 2rem;

        span {}
    }

    .scroll-cards {
        counter-reset: card;
        position: relative;
        display: block;
        // padding-bottom: 8rem;
        // outline: 1px solid green;
    }

    .scroll-cards > .scroll-cards__item + .scroll-cards__item {
        margin-top: 0rem;
    }

    .scroll-cards h1 {
        position: sticky;
        top: 2rem;
        font-size: 2em;
        margin: 0 0 0.5em;
    }

    .scroll-cards__item {
        color: #000;
        position: sticky;
        top: max(16vh, 10em);
        padding: 2em;
        min-height: 19em;
        // border-top: 4px solid white;
        border: 1px solid $gallery;
        border-radius: $border-radius;
        background-color: white;
        // background-color: $black-squeeze;
        // box-shadow: 0 8px 2px rgba(0, 0, 0, 0.1);
        // width: calc(100% - 5 * var(--offset));

        h2 {
            margin-left: 0;
            font-size: 1.4em;
        }

        ul {
            padding-left: 1rem;
            line-height: 180%;

            li {
                margin-left: 0;
                list-style-type: none;
                font-weight: 300;

                &:before {
                    position: relative;
                    display: inline-block;
                    left: -1rem;
                    content: "→";
                }
            }
        }

        p {
            font-weight: 400;
        }
    }

    .scroll-cards__item:nth-of-type(0) {
        transform: translate(calc((0) * var(--offset)), calc((0 - 1) * var(--offset)));
        // background-color: $remy;
    }
    .scroll-cards__item:nth-of-type(1) {
        transform: translate(calc((0) * var(--offset)), calc((1 - 1) * var(--offset)));
        // box-shadow: rgba(0, 0, 0, 0.025) 0px -14px 8px -8px;
        // background-color: $classic-rose;
    }
    .scroll-cards__item:nth-of-type(2) {
        transform: translate(calc((0) * var(--offset)), calc((2 - 1) * var(--offset)));
        box-shadow: rgba(0, 0, 0, 0.025) 0px -14px 8px -8px;
        // background-color: $blue-chalk;
    }
    .scroll-cards__item:nth-of-type(3) {
        transform: translate(calc((0) * var(--offset)), calc((3 - 1) * var(--offset)));
        box-shadow: rgba(0, 0, 0, 0.025) 0px -14px 8px -8px;
        // background-color: $selago;
    }
    .scroll-cards__item:nth-of-type(4) {
        transform: translate(calc((0) * var(--offset)), calc((4 - 1) * var(--offset)));
        box-shadow: rgba(0, 0, 0, 0.025) 0px -14px 8px -8px;
        // background-color: $link-water;
    }
    .scroll-cards__item:nth-of-type(5) {
        transform: translate(calc((0) * var(--offset)), calc((5 - 1) * var(--offset)));
        box-shadow: rgba(0, 0, 0, 0.025) 0px -14px 8px -8px;
        // background-color: $hawkes-blue;
    }
    .scroll-cards__item:nth-of-type(6) {
        transform: translate(calc((0) * var(--offset)), calc((6 - 1) * var(--offset)));
        box-shadow: rgba(0, 0, 0, 0.025) 0px -14px 8px -8px;
        // background-color: $hawkes-blue;
    }

    @media screen and (min-width: 37em) {
        h1 {
            // font-size: 3em;
        }
        .scroll-cards__item:before {
            counter-increment: card;
            content: "" counter(card);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.75em;
            height: 2.75em;
            background: #f09;
            color: #fff;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            left: 1.25em;
            top: 1.5em;
            font-weight: bold;
            // font-size: 1.2rem;
        }
        .scroll-cards__item {
            --offset: 1em;
            padding-left: 5em;

            &-1 {
                &:before {
                    background: $remy;
                    color: $magenta-vivid;
                }
                ul li:before {
                    color: $magenta-vivid;
                }
            }

            &-2 {
                &:before {
                    background: $classic-rose;
                    color: $vivid-violet;
                }
                ul li:before {
                    color: $vivid-violet;
                }
            }

            &-3 {
                &:before {
                    background: $blue-chalk;
                    color: $seance-purple;
                }
                ul li:before {
                    color: $seance-purple;
                }
            }

            &-4 {
                &:before {
                    background: $moon-raker;
                    color: $blue-violet;
                }
                ul li:before {
                    color: $blue-violet;
                }
            }

            &-5 {
                &:before {
                    background: $hawkes-blue;
                    color: $royal-blue;
                }
                ul li:before {
                    color: $royal-blue;
                }
            }

            &-6 {
                &:before {
                    background: $magenta-vivid;
                    color: white;
                }
            }
        }
    }

    @media screen and (min-width: 62em) {
        .scroll-cards h1 {
            font-size: 3em;
        }
        .scroll-cards__item {
            --offset: 1.25em;
            // max-width: 42em;
        }
    }
}

.container-expertise {
    padding-top: 4rem;
    background-color: $ebony2;
    background-image:
    radial-gradient(at 89% 39%, hsla(315,68%,64%,0.12) 0px, transparent 30%),
    radial-gradient(at 68% 41%, hsla(240,63%,65%,0.17) 0px, transparent 20%),
    radial-gradient(at 51% 46%, hsla(289,65%,74%,0.1) 0px, transparent 20%),
    radial-gradient(at 78% 53%, hsla(70,64%,69%,0.16) 0px, transparent 30%);
    background-size: 100% 70%;
    background-repeat: no-repeat;
    background-position: top 25rem right;

    .expertise {

        .card-container {
            &:hover {}
        }

        .card-outter {
            padding: 1px;
            background: $mirage;
            border-radius: var(--border-radius);

            &:hover {
                background: $gradient-card;
                transition: all 2.5s ease-in-out;

                .card {
                    background: $ebony2;
                }
            }
        
            .card {
                z-index: 2;
                height: 34rem;
                padding: 1rem;
                border: none;
                border-radius: 0;
                color: white;
                background: $ebony;
                border-radius: var(--border-radius);

                .card-body {

                    p {
                        max-width: auto;
                        font-optical-sizing: auto;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 1.8rem;
                        letter-spacing: -0.02rem;
                        line-height: 140%;
                    }

                    a {
                        max-width: 70%;
                        color: white;
                        font-size: 1.5rem;
                        font-family: $font-family-secondary;
                        opacity: .8;

                        &:hover {
                            opacity: 1;
                            text-decoration: none;
                        }
                    }
                }

                &.card-strategy {
                    .badge {
                        color: $picton-blue;
                    }
                }
                &.card-collaborate {
                    .badge {
                        color: $royal-blue;
                    }
                }
                &.card-campaign {
                    .badge {
                        color: $vivid-violet;
                    }
                }
                &.card-growth {
                    .badge {
                        color: $magenta-vivid;
                    }
                }
            }
        } 
    }

    // Cards grid
    .expertise-list {
        margin: 4rem 0 0 0;

        &_item {
            background: $ebony2;
            outline: 1px solid $mirage;
            border-radius: var(--border-radius);
            transition: all 0.3s ease-in-out;

            h3 a {
                color: white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            &-digital {
                &:hover {
                    outline: 1px solid $jon;
                    background-color: rgb(7, 11, 18);
                    -webkit-box-shadow:0px 0px 16px 12px rgba(238, 67, 164, 0.05);
                    -moz-box-shadow: 0px 0px 16px 12px rgba(238, 67, 164, 0.05);
                    box-shadow: 0px 0px 16px 12px rgba(238, 67, 164, 0.05);
                }
                i {
                    color: $magenta-vivid;
                }
            }
        
            &-marketing {
                &:hover {
                    outline: 1px solid $wine-berry;
                    background-color: rgb(7, 11, 18);
                    -webkit-box-shadow:0px 0px 16px 12px rgba(238, 67, 238, 0.05);
                    -moz-box-shadow: 0px 0px 16px 12px rgba(238, 67, 238, 0.05);
                    box-shadow: 0px 0px 16px 12px rgba(238, 67, 238, 0.05);
                }
                p a, i {
                    color: $vivid-violet;
                }
                
            }
        
            &-technology {
                &:hover {
                    outline: 1px solid $mirage2;
                    background-color: rgb(7, 11, 18); 
                    -webkit-box-shadow:0px 0px 16px 12px rgba(67, 97, 238,0.05);
                    -moz-box-shadow: 0px 0px 16px 12px rgba(67, 97, 238,0.05);
                    box-shadow: 0px 0px 16px 12px rgba(67, 97, 238,0.05);
                }
                p a, i {
                    color: $royal-blue;
                }
            }

            i {
                font-size: 2.5rem;
                transition: all 0.5s ease-out;
            }
        }
    }
}

.container-clients {
    .clients {
        padding: 0rem 0;

        .col:first-child {
            // margin-top: 8rem;
            padding-top: 8rem;
            // border-top: 1px solid $gallery;
        }

        img {
            max-width: 160px;
            height: auto;
            object-fit: scale-down;
            margin: 1rem 2.5rem;
        }
    }
}

.container-footer {
    height: auto;
    width: 100%;
    padding: 2rem 0;
    position: fixed !important;
    z-index: 0;
    bottom: 0;
    background-color: $ebony;
    // -webkit-clip-path: polygon(0 0, 1600px 8%, 1600px 100%, 0 100%);
    // clip-path: polygon(0 0, 1600px 8%, 1600px 100%, 0 100%);

    .footer {
        padding: 4rem 0;
        color: white;

        .footer-brand {
            display: block;
            margin-top: 0.25rem;
            width: 170px;
        }

        p {
            padding: 0;
            margin: 0;
            font-weight: 300;
            // line-height: 200%;
            // line-height: 1.5rem !important;

            a {
                color: white;
            }

            &.text-cta {
                font-size: 1.6rem;
                font-family: $font-family-secondary;
                color: white !important;

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.text-social:before {
                background: url("../img/logo/social-linkedin.png") no-repeat;
            }

            &.text-cred:before {
                background: url("../img/logo/lsosmark.png") no-repeat;
            }

            &.text-social,
            &.text-cred {
                position: relative;
                padding-left: 3rem;
                margin-bottom: 1.25rem;
                font-size: 1.6rem;
                line-height: 1.75rem;
                font-family: $font-family-secondary;
                opacity: 0.8 !important;

                a {
                    position: relative;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:before {
                    position: absolute;
                    top: -0.25rem;
                    left: 0;
                    width: 2rem;
                    height: 2rem;
                    content: "";
                    background-size: contain;
                    text-decoration: underline;
                }
            }

            &.text-muted {
                font-size: 1rem;
                color: white !important;
                line-height: 170%;
                opacity: 0.6 !important;
            }
        }
    }    
}

// CTA accordion
.accordion {
    margin: 4rem 0;
    background: none !important;

    .accordion-item {
        color: inherit;
        border: none;
        border-top: 1px solid $mirage;
        background: none !important;
        border-radius: 0 !important;
    }

    .accordion-header {
        border-radius: none;
        
        .accordion-button {
            color: white;
            font-size: 1.5rem;
            line-height: 1.8rem;
            background: none !important;
            box-shadow: none !important;

            &::after {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='58px' height='58px' stroke-width='2' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='%23292C30'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23292C30' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
            }

            &:not(.collapsed) {
                color: $color-brand-yellow;

                i {
                    color: white;
                }
                
                &::after {
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='58px' height='58px' stroke-width='2' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='%23F72585'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23F72585' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
                }
            }

            &:focus, &:active {
                box-shadow: none;
            }
        }    
    }

    .accordion-collapse {
        background: none !important;

        .accordion-body {
            p {
                a { 
                    color: rgba(247, 37, 133, 1);

                    &:hover {
                        color: rgba(247, 37, 133, 0.85);
                    }
                }
            }
        }
    }
}

.section-container {
    margin: 6rem 0 0 0;
    padding: 8rem 0;
    clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);
    background: linear-gradient(50deg, rgba(8,14,24,1) 0%, rgba(15,22,35,1) 100%);
}

.container-wewill {
    margin: 6rem 0 0 0;

    h2 {
        font-size: 2rem;
    }

    h4 {
        color: white;

        i {
            color: $color-brand-yellow;
        }
    }

    p {
        font-weight: 400;
        
        strong {
            color: white;
            font-weight: 400;
        }
    }

    .card.card-wewill {
        // border-color:$ribbon-blue;
        // border-color: $mirage;
        border: none;
        background: none;
        
        img {
            max-width: 100%;
            height: auto;
            // object-fit: scale-down;
            border-radius: $border-radius; 
        }
    }

}

.table.table-borderless {
    thead {
        font-weight: 600;
    }
    td {
        padding:0.5rem 1rem;
    }
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
   
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

    .wrapper {
        padding-bottom: 8rem;
        margin-bottom: 28rem;
    }

    header {    
        .navbar-nav {
            margin-top: 0;
            border-bottom: none;
    
            .nav-item {
                padding: 0rem 1.4rem;
                border-top: none;
            }
        }

        .dropdown {
            
            &.show {
                transition: all 0.25s ease-out;
            }
            
            ul.dropdown-menu {
                border-radius: $border-radius;
                box-shadow: none;
                margin-top: 1rem;
                width: auto;
                background-color: $ebony;
                border: 1px solid $pale-sky !important;
                box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .3), 0 1px 4px 0 rgba(0, 0, 0, .3) !important;

                li a.dropdown-item {
                    padding: 0.5rem 1.35rem !important;
                    color: white;

                    &.active {
                        color: $color-primary;
                        background-color: transparent;
                        background-image: none;
                    }

                    &:hover {
                        color: $color-primary;
                        background-color: transparent;
                        background-image: none;
                    }
                }
            }    
        }

        .dropdown ul.dropdown-menu:before {
            visibility: visible !important;
        }
        .dropdown ul.dropdown-menu:after {
            visibility: visible !important;
        }
    }

    .container-hero {    
        .hero {
            padding: 10rem 0;
            h1 {
                font-size: 5rem;
            }
        }
    }

    .container-hero-services {
        margin-bottom: 18rem;
        
        .hero-services {
            padding: 5rem 0 0 0;
        }
    }

    .container-hero-process {
        margin-bottom: 18rem;
        
        .hero-process {
            padding: 5rem 0 0 0;
        }
    }

    .container-features {
        .features {    
            .card {
                padding: 2rem;
            }
        }
    }

    .container-process {
        padding-bottom: 6rem;
        margin-top: 6rem;
        background-size: 75% 100%;
        background-position: top 10% right -10%;

        .scroll-cards__item {
            padding: 2em 3.5em;

            h2 {
                margin-left: 1.5rem;
            }
        }
    }

    .container-expertise {
        padding-top: 8rem;

        .expertise {
            .card-outter {        
                .card {
                    padding: 2rem;
                }
            }
        }
    }

    .features {
        .card-container {}
        .card {
            padding: 3rem;

            .card-body { 
                p {
                    max-width: 60%;
                    font-size: 1.8rem;
                }
            }
        }
    }

    .service-container {
        a.service-card {
            width: 40%;

            &:nth-child(odd) {
                margin-right: 2.5rem !important;
                // outline: 1px solid red;
                h3 {
                    padding-right: 15%;
                }
            }
            &:nth-child(even) {
                margin-left: 2.5rem !important;
                // outline: 1px solid green;
            }
        }    
    }

    .section-container {
        margin: 6rem 0 0 0;
        padding: 12rem 0 8rem 0;
        clip-path: polygon(0 6%, 100% 0%, 100% 94%, 0% 100%);
        background: rgb(15,22,35);
        background: rgb(8,14,24);
        background: linear-gradient(50deg, rgba(8,14,24,1) 0%, rgba(15,22,35,1) 100%);
    }

    // CTA accordion
    .accordion {
        margin: 4rem 0;

        .accordion-header {
            
            .accordion-button {
                color: white;
                font-size: 1.5rem;
                line-height: 3rem;
            }
        }
    }

    .container-hero-services {
        margin-bottom: 10rem;
        .hero-services {
            padding: 10rem 0;
            // h1 {font-size: 9rem;}
        }
    }

    .container-hero-process {
        margin-bottom: 10rem;
        .hero-process {
            padding: 10rem 0;
            // h1 {font-size: 9rem;}
        }
    }

    .container-hero-sector {
        // margin-bottom: 10rem;
        .hero-sector {
            padding: 10rem 0;
            // h1 {font-size: 9rem;}
        }
    }

    .container-process {

        .intro {
            position: relative;
            min-height: 19em;
    
            span {
                position: sticky;
                top: max(16vh, 10em);
            }
        }
    }

    .container-clients {
        .clients {    
            img {
                margin: 3rem 2.5rem;
            }
        }
    }

    .container-values-list {    
        .values-list {
            .list-group {
                .list-group-item {
                    padding: 0.85rem 1.25rem 0.85rem 1.25rem;
                    line-height: 1.5rem;
                }
            }
        }
    }

    .footer {

        .footer-brand {
        }

        p {
            // line-height: 3.5rem !important;
            
            &.text-cta {
                font-size: 1.8rem;
            }

            &.text-social,
            &.text-cred {
                padding-left: 3rem;
                font-size: 1.6rem;
                // line-height: 4rem;
            }
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { }